import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import interactionPlugin from "@fullcalendar/interaction";
import plLocale from "@fullcalendar/core/locales/pl";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../context";
import { format } from "date-fns";
import s from "./index.module.css";
import EventDetailsSidebar from "../components/event-details-sidebar";
import axios from "axios";
import { saveEvent } from "../event";

import imageCalendarOn from "../assets/calendar_on.png";
import imageCalendarOff from "../assets/calendar_off.png";
import clearThisDay from "../assets/clear_this_day.png";
import { CellImgWrapper } from "./style-components/cell-img-wrapper";
import { MySpan } from "./style-components/my-span";
import { createRenderEventContent } from "./createRenderEventContent";

function Calendar() {
  const context = useContext(AppContext);
  const [items, setItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [nonSchedulableDays, setNonSchedulableDays] = useState({});
  const [nonSchulableDaysFetched, setNonSchulableDaysFetched] = useState(false);
  // const iAmLector = context.profile.personType === "Lector";
  // const iAmListener = context.profile.personType === "Listener";
  const iAmAdministrator = context.profile.personType === "Administrator";
  const [editedEvent, setEditedEvent] = useState(null);
  const [editedEventId, setEditedEventId] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPeriodStart, setSelectedPeriodStart] = useState();
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState();
  const [eventsFetched, setEventsFetched] = useState(false);

  const onNavlinkDayClick = (date) => {
    return false;
  };

  const onSaveEvent = async (event, participants, occurrences) => {
    const isOk = await saveEvent(event, participants, occurrences);

    await fetchCalendarEventsForCurrentView(context.profile.personType);

    return isOk;
  };

  const dayAvailable = (date) => {
    const day = format(date, "yyyy-MM-dd");
    const isAvailable = !nonSchedulableDays[day];
    return isAvailable;
  };

  const deleteEvent = async (id) => {
    const response = await axios.delete(`/calendar/scheduled_call/${id}`);
    const isOk = response.status === 204;

    if (!isOk) {
      throw new Error({
        message: "Nie udało się usunąć wydarzenia",
        response,
      });
    }

    await fetchCalendarEventsForCurrentView(context.profile.personType);
  };

  async function fetchCalendarEventsForCurrentView(personType) {
    const items = await context.fetchCalendarEvents(new Date(), personType);
    setItems(items);

    setEventsFetched(true);
  }

  async function fetchNonSchedulableDays(
    selectedPeriodStart,
    selectedPeriodEnd
  ) {
    const items = await context.fetchNonSchedulableDays(
      selectedPeriodStart,
      selectedPeriodEnd
    );
    setNonSchedulableDays(
      items.reduce((acc, item) => {
        acc[format(new Date(item.date), "yyyy-MM-dd")] = true;
        return acc;
      }, {})
    );
    setNonSchulableDaysFetched(true);
  }

  useEffect(() => {
    fetchNonSchedulableDays(selectedPeriodStart, selectedPeriodEnd);
  }, [selectedPeriodStart, selectedPeriodEnd]);

  useEffect(() => {
    if (context.profile.personType) {
      fetchCalendarEventsForCurrentView(context.profile.personType);
    }
  }, [context.profile]);

  // const personDataToPersonName = (item) => {
  //   if (iAmLector) {
  //     return `${item.listener.firstName} ${item.listener.surname}`;
  //   } else if (iAmListener) {
  //     return `${item.lector.firstName} ${item.lector.surname}`;
  //   } else if (iAmAdministrator) {
  //     return (
  //       <span>
  //         lektor:{" "}
  //         <strong>
  //           {item.lector.firstName} {item.lector.surname}
  //         </strong>
  //         <br />
  //         słuchacz:{" "}
  //         <strong>
  //           {item.listener.firstName} {item.listener.surname}
  //         </strong>
  //       </span>
  //     );
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    setEvents(
      items.map((scheduledCall) => ({
        title: scheduledCall.label,
        start: new Date(scheduledCall.startAt),
        end: new Date(scheduledCall.endAt),
        extendedProps: {
          // personName: personDataToPersonName(scheduledCall),
          id: scheduledCall.id,
          supportsRecording: scheduledCall.supportsRecording,
        },
      }))
    );
  }, [items]);

  // const timeSelected = (data) => {};
  // const timeUnselected = (data) => {};
  const clickEvent = (data) => {
    if (data.event) {
      setEditedEventId(data.event.extendedProps.id);
      setModalIsOpen(true);
    }
  };

  const clickDate = (data) => {
    setEditedEventId(undefined);
    setSelectedDate(data.date);
    setModalIsOpen(!modalIsOpen);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditedEvent(null);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const calendarRef = useRef();

  const renderFunction = createRenderEventContent(context.profile);

  const [calendarView, setCalendarView] = useState("dayGridMonth"); // default view

  const toggleScheduleAvailabilityForDate = (e, date) => {
    e.stopPropagation();
    e.preventDefault();

    console.log(date);
    if (dayAvailable(date)) {
      if (context.scheduleDayOff(date)) {
        setNonSchedulableDays({
          ...nonSchedulableDays,
          [format(date, "yyyy-MM-dd")]: true,
        });
      }
    } else {
      if (context.scheduleDayOn(date)) {
        const prop = format(date, "yyyy-MM-dd");
        // remove property from object
        const { [prop]: _, ...rest } = nonSchedulableDays;
        setNonSchedulableDays(rest);
      }
    }
  };

  const clearAllEventsFromDate = async (e, date) => {
    e.stopPropagation();
    e.preventDefault();
    const prop = format(date, "yyyy-MM-dd");
    const removedEvents = await context.removeAllEventsFromDate(date);

    if (removedEvents) {
      setEvents(
        events.filter(
          (event) => !removedEvents.includes(event.extendedProps.id)
        )
      );
    }
  };

  if (!nonSchulableDaysFetched) {
    return <div>Ładowanie...</div>;
  }

  return (
    <div className={s.contentWrapper}>
      {modalIsOpen && (
        <EventDetailsSidebar
          closeModal={closeModal}
          editedEvent={editedEvent}
          editedEventId={editedEventId}
          setEditedEvent={setEditedEvent}
          selectedDate={selectedDate}
          saveEvent={onSaveEvent}
          deleteEvent={deleteEvent}
        />
      )}

      <FullCalendar
        headerToolbar={{
          left: "title",
          center: "",
          right: "dayGridMonth,timeGridWeek,timeGridDay prev,next today",
        }}
        nowIndicator={true}
        navLinks={true}
        navLinkDayClick={onNavlinkDayClick}
        windowResizeDelay={500}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        // editable={true}
        initialView={calendarView}
        eventContent={renderFunction}
        events={events}
        height="100%"
        // width="100%"
        locale={plLocale}
        displayEventTime={true}
        dayMaxEventRows={true}
        displayEventEnd={true}
        dateClick={iAmAdministrator ? clickDate : null}
        eventClick={iAmAdministrator ? clickEvent : null}
        datesSet={(arg) => {
          setSelectedPeriodStart(arg.start);
          setSelectedPeriodEnd(arg.end);
        }}
        // select={timeSelected}
        // unselect={timeUnselected}
        dayCellClassNames={(arg) => {
          if (!dayAvailable(arg.date)) {
            return "day-off";
          }

          return [];
        }}
        dayCellContent={(arg) => {
          return (
            <>
              {iAmAdministrator ? (
                <CellImgWrapper
                  src={clearThisDay}
                  onClick={(e) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (
                      // eslint-disable-next-line no-restricted-globals
                      confirm(
                        `Czy na pewno usunąć wszystkie wydarzenia z dnia ${format(
                          arg.date,
                          "dd MM yyyy"
                        )}?`
                      )
                    ) {
                      clearAllEventsFromDate(e, arg.date);
                    }
                  }}
                />
              ) : null}

              <CellImgWrapper
                src={imageCalendarOn}
                {...(iAmAdministrator
                  ? {
                      onClick: (e) =>
                        toggleScheduleAvailabilityForDate(e, arg.date),
                    }
                  : {})}
              />

              <MySpan>{arg.dayNumberText}</MySpan>
            </>
          );
        }}
      />
    </div>
  );
}

export default Calendar;
