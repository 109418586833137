import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AppContext from "../context";

const MainBar = styled.div`
  width: 120px;
  background-color: lightgray;
`;

const MainBarItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid gray;
  &:hover {
    background-color: gray;
  }
`;

const LinkLikeSpan = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const Sidebar = ({ addEventClicked }) => {
  const context = useContext(AppContext);
  return (
    <MainBar>
      <MainBarItem>
        <Link to="/calendar" className="menu-link-to-calendar">
          Kalendarz
        </Link>
      </MainBarItem>
      {context?.profile?.personType !== "Administrator" && (
        <MainBarItem>
          <Link to="/profile" className="menu-link-to-profile">
            Profil
          </Link>
        </MainBarItem>
      )}
      {context?.profile?.personType === "Lector" && (
        <MainBarItem>
          <Link to="/desktop-app" className="menu-link-to-desktop-app">
            Aplikacja
          </Link>
        </MainBarItem>
      )}

      {context?.profile?.personType === "Administrator" && (
        <MainBarItem>
          <Link to="/users">Użytkownicy</Link>
        </MainBarItem>
      )}

      {context?.profile?.personType === "Administrator" && (
        <MainBarItem>
          <LinkLikeSpan
            onClick={() => {
              if (addEventClicked) {
                addEventClicked();
              }
            }}
          >
            Dodaj lekcję
          </LinkLikeSpan>
        </MainBarItem>
      )}
    </MainBar>
  );
};

export default Sidebar;
