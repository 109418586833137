import axios from "axios";

export const generateAuthTokenAndOpenWebapp = async (e, callId) => {
  e.preventDefault();

  const config = (await axios.get("/config")).data;

  const { videoUrl } = config;

  const token = (await axios.post("/auth/generate-auth-token")).data;
  if (token) {
    window.location.href = `${videoUrl}?authToken=${token}&callId=${callId}`;
  }
};
