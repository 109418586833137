import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AppContext from "../context";
import "react-notifications-component/dist/theme.css";
import { notifyError } from "../components/notification";
const AddUser = () => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [role, setRole] = useState("Listener");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [formIsValid, setFormIsValid] = useState(false);

  const existingUserId = useParams().existingUserId;

  const { fetchUsers, fetchUserData } = useContext(AppContext);

  const editMode = !!existingUserId;
  const [fetchingUserDataStarted, setFetchingUserDataStarted] = useState(false);

  useEffect(() => {
    if (existingUserId) {
      setFormIsValid(firstName && surname);
    } else {
      setFormIsValid(firstName && surname && role && email && password);
    }
  }, [firstName, surname, role, email, password, existingUserId]);

  useEffect(() => {
    if (existingUserId && !fetchingUserDataStarted) {
      setFetchingUserDataStarted(true);
      fetchUserData(existingUserId).then((userData) => {
        setFirstName(userData.firstName);
        setSurname(userData.surname);
        setEmail(userData.email);
      });
    }
  }, [existingUserId, fetchingUserDataStarted]);

  async function submitNewUserData() {
    try {
      const response = await axios.post("/person", {
        firstName,
        surname,
        personType: role,
        email,
        password,
      });

      if (response.status === 201) {
        fetchUsers();
        navigate("/users");
      }
    } catch (e) {
      const message = e?.response?.data?.message;

      if (message === "email_already_used") {
        notifyError({
          title: "Email jest już używany",
        });
      }
    }
  }

  async function submitEditedUserData() {
    try {
      const response = await axios.patch(`/person/${existingUserId}`, {
        firstName,
        surname,
      });

      if (response.status === 200) {
        fetchUsers();
        navigate("/users");
      }
    } catch (e) {
      // const message = e?.response?.data?.message;
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      if (editMode) {
        await submitEditedUserData();
      } else {
        await submitNewUserData();
      }
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <table>
          <thead>
            <tr>
              <td>Imię:</td>
              <td>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Nazwisko:</td>
              <td>
                <input
                  type="text"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </td>
            </tr>
            {!existingUserId ? (
              <tr>
                <td>Rola:</td>
                <td>
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="Listener">Student</option>
                    <option value="Lector">Nauczyciel</option>
                    <option value="Administrator">Administrator</option>
                    <option value="RecorderBot">Bot nagrywający</option>
                  </select>
                </td>
              </tr>
            ) : null}
            {!existingUserId ? (
              <tr>
                <td>Email:</td>
                <td>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </td>
              </tr>
            ) : null}
            {!existingUserId ? (
              <tr>
                <td>Hasło:</td>
                <td>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td></td>
              <td>
                <button onClick={() => navigate("/users")}>Anuluj</button>
                <button type="submit" disabled={!formIsValid}>
                  {editMode ? "Zapisz" : "Dodaj"}
                </button>
              </td>
            </tr>
          </thead>
        </table>
      </form>
    </div>
  );
};

export default AddUser;
