import { StyledDeleteButton, StyledTab } from "./style-components/tab";
import { format } from "date-fns";

import deleteImg from "../assets/delete.png";

export const Tab = ({ key, item, canDelete, onDelete, onActivate, active }) => {
  const dateString = format(item.date, "yyyy-MM-dd");
  return (
    <StyledTab active={active}>
      <span onClick={onActivate}>{dateString}</span>{" "}
      {canDelete ? (
        <StyledDeleteButton src={deleteImg} onClick={onDelete} />
      ) : null}
    </StyledTab>
  );
};
