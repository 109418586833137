import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Main from "./main";
import LoginPage from "./login";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "./context";
import axios from "axios";
import LogoutPage from "./logout";
import Calendar from "./calendar";
import Users from "./users";
import AddUser from "./add-user";
import Profile from "./profile";
import { ReactNotifications } from "react-notifications-component";
import DesktopApp from "./desktop-app";
import ChangeUserPassword from "./change-user-password";
import { format } from "date-fns";
import spinnerImg from "./assets/spinner.png";
import styled from "styled-components";

const CenteredScreenSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: #ccc;
  opacity: 0.5;
`;

const RotatingSpinner = styled.img`
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  height: 50px;
  width: 50px;
`;

const Spinner = () => {
  const { savingSomething } = useContext(AppContext);

  if (!savingSomething) {
    return null;
  }

  return (
    <CenteredScreenSpinnerContainer>
      <RotatingSpinner src={spinnerImg} />
    </CenteredScreenSpinnerContainer>
  );
};

function App() {
  const [context, setContext] = useState({
    authenticated: false,
    profile: {
      firstName: "",
      surname: "",
      email: "",
    },
    users: [],
    messages: [],
    savingSomething: false,
    savingSomethingSinceMs: 0,
    savingSomethingTimeoutId: -1,
    setUsers: (users) => {
      setContext((c) => ({ ...c, users }));
    },
    setAuthenticated: (authenticated) => {
      setContext((c) => ({ ...c, authenticated }));
    },
    setProfile: (profile) => {
      setContext((c) => ({ ...c, profile }));
    },
    login: async ({ email, password }) => {
      const response = await axios.post("/auth/login", { email, password });

      if (response.status === 200) {
        const data = (await response).data;

        context.setAuthenticated(true);
        context.setProfile(data);

        context.identity();

        return true;
      }

      return false;
    },
    identity: async () => {
      const response = await axios.get("/auth/identity");

      if (response.status === 200) {
        const data = (await response).data;

        context.setAuthenticated(true);
        context.setProfile(data);

        if (data.personType === "Administrator") {
          await context.fetchUsers();
        }

        return true;
      }

      return false;
    },
    updateProfilePreferences: async (preferences) => {
      const response = await axios.patch("/person/preferences", preferences);

      if (response.status === 200) {
        await context.identity();
      }
    },
    logout: async () => {
      await axios.post("/auth/logout");
      context.setAuthenticated(false);
      context.setProfile({});
    },
    fetchUsers: async () => {
      const response = await axios.get("/person");
      context.setUsers(response.data);
    },
    fetchUserData: async (id) => {
      const response = await axios.get(`/person/${id}`);
      return response.data;
    },
    fetchCalendarEvents: async (date, personType) => {
      let response;
      if (personType === "Administrator") {
        response = await axios.get("/calendar/scheduled_call", {
          params: {
            date: date.toISOString(),
          },
        });
      } else {
        response = await axios.get("/calendar/scheduled_call/my", {
          params: {
            date: date.toISOString(),
          },
        });
      }

      if (response.status === 200) {
        return (await response).data;
      }

      return [];
    },

    fetchNonSchedulableDays: async (_start, _end) => {
      if (!!_start && !!_end) {
        const response = await axios.get("/calendar/non_schedulable_day", {
          params: {
            start: _start.toISOString(),
            end: _end.toISOString(),
          },
        });

        if (response.status === 200) {
          return (await response).data;
        }
      }

      return [];
    },

    scheduleDayOn: async (date) => {
      const response = await axios.delete(
        `/calendar/non_schedulable_day/${format(date, "yyyy-MM-dd")}`
      );

      return response.status === 200;
    },

    scheduleDayOff: async (date) => {
      const response = await axios.post(`/calendar/non_schedulable_day/`, {
        label: "-",
        date: format(date, "yyyy-MM-dd"),
      });

      return response.status === 200;
    },

    removeAllEventsFromDate: async (date) => {
      const response = await axios.delete(
        `/calendar/scheduled_call/all_by_date/${format(date, "yyyy-MM-dd")}`
      );

      if (response.status === 200) {
        return (await response).data;
      } else {
        return [];
      }
    },

    setSavingSomething: (flag) => {
      if (flag) {
        setContext((c) => {
          if (context.savingSomethingTimeoutId !== -1) {
            clearTimeout(context.savingSomethingTimeoutId);
          }

          return {
            ...c,
            savingSomething: flag,
            savingSomethingSinceMs: new Date().getTime(),
            savingSomethingTimeoutId: -1,
          };
        });
      } else {
        setContext((cc) => {
          const diff = new Date().getTime() - cc.savingSomethingSinceMs;

          if (diff < 200) {
            const id = setTimeout(() => {
              setContext((c) => ({
                ...c,
                savingSomething: flag,
                savingSomethingSinceMs: 0,
                savingSomethingTimeoutId: -1,
              }));
            }, 200 - diff);

            setContext((c) => ({
              ...c,
              savingSomethingTimeoutId: id,
            }));

            return cc;
          } else {
            return {
              ...cc,
              savingSomething: false,
              savingSomethingSinceMs: 0,
            };
          }
        });
      }
    },

    info: (msg) => {
      setContext((c) => ({
        ...c,
        messages: [
          ...c.messages,
          {
            type: "info",
            msg,
            createdAt: new Date(),
          },
        ],
      }));
    },
    warning: (msg) => {
      setContext((c) => ({
        ...c,
        messages: [
          ...c.messages,
          {
            type: "warning",
            msg,
            createdAt: new Date(),
          },
        ],
      }));
    },
    error: (msg) => {
      setContext((c) => ({
        ...c,
        messages: [
          ...c.messages,
          {
            type: "error",
            msg,
            createdAt: new Date(),
          },
        ],
      }));
    },
  });

  useEffect(() => {
    context.identity();
  }, []);

  return (
    <AppContext.Provider value={context}>
      <div className="App">
        <ReactNotifications />
        <Spinner />

        <HashRouter>
          <Routes>
            <Route path="/" element={<Main />}>
              <Route path="calendar" element={<Calendar />} />
              {context?.profile?.personType === "Administrator" ? (
                <>
                  <Route path="users" element={<Users />} />
                  <Route path="add-user" element={<AddUser />} />
                  <Route
                    path="edit-user/:existingUserId"
                    element={<AddUser />}
                  />
                  <Route
                    path="change-user-password/:existingUserId"
                    element={<ChangeUserPassword />}
                  />
                </>
              ) : (
                <>
                  <Route path="profile" element={<Profile />} />
                  <Route
                    path="change-password"
                    element={<ChangeUserPassword />}
                  />
                </>
              )}
              )
              {context?.profile?.personType === "Lector" ? (
                <Route path="desktop-app" element={<DesktopApp />} />
              ) : null}
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />

            {/*<Route path="/calendar" element={<Calendar />} />*/}
          </Routes>
        </HashRouter>
      </div>{" "}
    </AppContext.Provider>
  );
}

export default App;
