import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context";
import styled from "styled-components";
import { Link } from "react-router-dom";
import spinnerImg from "../assets/spinner.png";

const SelectableOption = styled.span`
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  &:hover {
    background-color: lightgray;
  }
`;

const Profile = () => {
  const {
    profile,
    updateProfilePreferences,
    setSavingSomething,
    savingSomething,
    savingSomethingSinceMs,
  } = useContext(AppContext);

  const [updatedPreferences, setUpdatedPreferences] = useState({});

  useEffect(() => {
    setUpdatedPreferences({ ...profile });
  }, [profile]);

  const {
    openCallsWith,
    miniViewPlacement,
    hideChatByDefault,
    openMiniView,
    virtualBackgroundEnabledByDefault,
  } = updatedPreferences;

  const updateLocalProfilePreferences = (newPreferences) => {
    setUpdatedPreferences({
      ...updatedPreferences,
      ...newPreferences,
    });
  };

  const saveProfilePreferences = async () => {
    setSavingSomething(true);
    await updateProfilePreferences(updatedPreferences);
    setSavingSomething(false);
  };

  const resetPreferences = () => {
    setUpdatedPreferences({ ...profile });
  };

  const isLector = profile.personType === "Lector";

  return (
    <div>
      <h3>Profil użytkownika</h3>
      {
        isLector ? (
          <>
            <div>Rozmowa w kalendarzu otwierana w:</div>
            <ul>
              <li>
                <SelectableOption
                  selected={openCallsWith === "web"}
                  onClick={() => {
                    updateLocalProfilePreferences({ openCallsWith: "web" });
                  }}
                >
                  Przeglądarce
                </SelectableOption>
              </li>
              <li>
                <SelectableOption
                  selected={openCallsWith === "desktop"}
                  onClick={() => {
                    updateLocalProfilePreferences({ openCallsWith: "desktop" });
                  }}
                >
                  Dedykowanej aplikacji okienkowej (do pobrania{" "}
                  <Link to={"/desktop-app"}>tutaj</Link>)
                </SelectableOption>
              </li>
            </ul>
          </>
        ) : null}

      {isLector ? (
        <div>
          <div>
            Czy otwierać okienko z podglądem miniaturek w trakcie wspóldzielenia
            ekranu?
          </div>
          <ul>
            <li>
              <SelectableOption
                selected={openMiniView === true}
                onClick={() => {
                  updateLocalProfilePreferences({ openMiniView: true });
                }}
              >
                Tak
              </SelectableOption>
            </li>
            <li>
              <SelectableOption
                selected={openMiniView === false}
                onClick={() => {
                  updateLocalProfilePreferences({ openMiniView: false });
                }}
              >
                Nie
              </SelectableOption>
            </li>
          </ul>
        </div>
      ) : null}

      <div>Umiejscowienie miniaturek osób podczas rozmowy:</div>
      <ul>
        <li>
          <SelectableOption
            selected={miniViewPlacement === "ldp"}
            onClick={() => {
              updateLocalProfilePreferences({ miniViewPlacement: "ldp" });
            }}
          >
            Lewy dolny róg, lista rośnie w prawo
          </SelectableOption>
        </li>
        {/*<li>*/}
        {/*  <SelectableOption*/}
        {/*    selected={miniViewPlacement === "ldg"}*/}
        {/*    onClick={() => {*/}
        {/*      updateLocalProfilePreferences({ miniViewPlacement: "ldg" });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Lewy dolny róg, lista rośnie w górę*/}
        {/*  </SelectableOption>*/}
        {/*</li>*/}
        <li>
          <SelectableOption
            selected={miniViewPlacement === "pdl"}
            onClick={() => {
              updateLocalProfilePreferences({ miniViewPlacement: "pdl" });
            }}
          >
            Prawy dolny róg, lista rośnie w lewo
          </SelectableOption>
        </li>
        {/*<li>*/}
        {/*  <SelectableOption*/}
        {/*    selected={miniViewPlacement === "pdg"}*/}
        {/*    onClick={() => {*/}
        {/*      updateLocalProfilePreferences({ miniViewPlacement: "pdg" });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Prawy dolny róg, lista rośnie w górę*/}
        {/*  </SelectableOption>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <SelectableOption*/}
        {/*    selected={miniViewPlacement === "pgl"}*/}
        {/*    onClick={() => {*/}
        {/*      updateLocalProfilePreferences({ miniViewPlacement: "pgl" });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Prawy górny róg, lista rośnie w lewo*/}
        {/*  </SelectableOption>*/}
        {/*</li>*/}
        <li>
          <SelectableOption
            selected={miniViewPlacement === "pgd"}
            onClick={() => {
              updateLocalProfilePreferences({ miniViewPlacement: "pgd" });
            }}
          >
            Prawy górny róg, lista rośnie w dół
          </SelectableOption>
        </li>
        {/*<li>*/}
        {/*  <SelectableOption*/}
        {/*    selected={miniViewPlacement === "lgp"}*/}
        {/*    onClick={() => {*/}
        {/*      updateLocalProfilePreferences({ miniViewPlacement: "lgp" });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Lewy górny róg, lista rośnie w prawo*/}
        {/*  </SelectableOption>*/}
        {/*</li>*/}
        <li>
          <SelectableOption
            selected={miniViewPlacement === "lgd"}
            onClick={() => {
              updateLocalProfilePreferences({ miniViewPlacement: "lgd" });
            }}
          >
            Lewy górny róg, lista rośnie w dół
          </SelectableOption>
        </li>
      </ul>

      <div>
        Domyślnie minimalizuj okienko czata:{" "}
        <ul>
          <li>
            <SelectableOption
              selected={hideChatByDefault}
              onClick={() => {
                updateLocalProfilePreferences({ hideChatByDefault: true });
              }}
            >
              tak
            </SelectableOption>
          </li>
          <li>
            <SelectableOption
              selected={!hideChatByDefault}
              onClick={() => {
                updateLocalProfilePreferences({ hideChatByDefault: false });
              }}
            >
              nie
            </SelectableOption>
          </li>
        </ul>
      </div>
      <div>
        Wirtualne tło domyślnie:{" "}
        <ul>
          <li>
            <SelectableOption
              selected={virtualBackgroundEnabledByDefault}
              onClick={() => {
                updateLocalProfilePreferences({
                  virtualBackgroundEnabledByDefault: true,
                });
              }}
            >
              włączone
            </SelectableOption>
          </li>
          <li>
            <SelectableOption
              selected={!virtualBackgroundEnabledByDefault}
              onClick={() => {
                updateLocalProfilePreferences({
                  virtualBackgroundEnabledByDefault: false,
                });
              }}
            >
              wyłączone
            </SelectableOption>
          </li>
        </ul>
      </div>

      <div>
        <button onClick={saveProfilePreferences}>Zapisz</button>
        <button onClick={resetPreferences}>Przywróć</button>
      </div>

      <hr />

      <Link to={"/change-password"}>Zmień hasło</Link>
    </div>
  );
};

export default Profile;
