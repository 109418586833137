import React, { useContext, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import s from "./index.module.css";
import { Link } from "react-router-dom";
import AppContext from "../context";
import styled from "styled-components";

const ROLES = {
  Administrator: "Administrator",
  Listener: "Student",
  Lector: "Nauczyciel",
  RecorderBot: "Bot nagrywający",
};

const Spacer = styled.span`
  margin: 0 5px;
`;

const Users = () => {
  const { users, fetchUsers } = useContext(AppContext);

  const removeUser = async (id, e) => {
    e.preventDefault();

    await axios.delete(`/person/${id}`);
    fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <div>
        <Link to={"/add-user"}>Dodaj użytkownika</Link>
      </div>

      <table className={s.table}>
        <thead>
          <tr className={s.tr}>
            <th>Imię</th>
            <th>Nazwisko</th>
            <th>Rola</th>
            <th>Email</th>
            <th>Data utworzenia</th>
            <th>Opcje</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u, i) => (
            <tr className={s.tr} key={i}>
              <td>{u.firstName}</td>
              <td>{u.surname}</td>
              <td>{ROLES[u.personType]}</td>
              <td>{u.email}</td>
              <td>{format(new Date(u.createdAt), "yyyy-MM-dd hh:MM")}</td>
              <td>
                <Link to={`/edit-user/${u.id}`}>Edytuj</Link>
                <Spacer>|</Spacer>
                <Link to={`/change-user-password/${u.id}`}>Zmień hasło</Link>
                <Spacer>|</Spacer>
                <a href="#" onClick={(e) => removeUser(u.id, e)}>
                  Usuń
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
