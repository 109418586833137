import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context";

const LogoutPage = () => {
  const navigate = useNavigate();

  const { logout, authenticated, setSavingSomething } = useContext(AppContext);

  useEffect(() => {
    async function f() {
      setSavingSomething(true);
      await logout();
      setSavingSomething(false);
    }

    f();
  }, []);

  if (!authenticated) {
    navigate("/");
  }

  return (
    <div>
      <h3>Wylogowywanie...</h3>
    </div>
  );
};

export default LogoutPage;
