import { format } from "date-fns";
import { RedBall } from "./style-components/red-ball";
import { RunTheCall } from "./style-components/run-the-call";
import { generateAuthTokenAndOpenWebapp } from "./generateAuthTokenAndOpenWebapp";
import { generateAuthTokenAndOpenApp } from "./generateAuthTokenAndOpenApp";

export const createRenderEventContent = ({ personType, openCallsWith }) => {
  return (eventInfo) => {
    const isLector = personType === "Lector";
    const callId = eventInfo.event.extendedProps.id;
    const rec = eventInfo.event.extendedProps.supportsRecording;
    return (
      <span className="">
        <div className="fc-event-time">
          <small>
            {format(eventInfo.event.start, "HH:mm")} -{" "}
            {format(eventInfo.event.end, "HH:mm")} {eventInfo.event.title}
            {rec ? (
              <span>
                {" "}
                <RedBall title="Możliwość nagrywania rozmowy" />
              </span>
            ) : null}
          </small>
        </div>
        {/*<div className="fc-event-title">{eventInfo.event.title}</div>*/}
        {/*<div className="fc-event-mirror">*/}
        {/*  {eventInfo.event.extendedProps.personName}*/}
        {/*</div>*/}
        {personType !== "Administrator" ? (
          <div>
            {(openCallsWith === "web" || ! isLector) ? (

              <RunTheCall
                className="run-the-call"
                href=""
                onClick={(e) => generateAuthTokenAndOpenWebapp(e, callId)}
                title="Aby zmienić jak uruchamine są rozmowy z kalendarza - sprawdź ustawienia w profilu"
              >
                Uruchom
              </RunTheCall>
            ) : (
              <RunTheCall
                className="run-the-call"
                href=""
                onClick={(e) => generateAuthTokenAndOpenApp(e, callId)}
                title="Aby zmienić jak uruchamine są rozmowy z kalendarza - sprawdź ustawienia w profilu"
              >
                Uruchom
              </RunTheCall>
            )}
          </div>
        ) : null}
      </span>
    );
  };
};
