import { createContext } from "react";

const AppContext = createContext({
  authenticated: false,
  profile: {
    firstName: "",
    surname: "",
    email: "",
    openCallsWith: "",
    miniViewPlacement: "",
    hideChatByDefault: "",
    openMiniView: false,
  },
  savingSomething: false,
  users: [],
  messages: [],
  setUsers: (_users) => {},
  fetchUsers: () => {},
  fetchUserData: () => {},
  setAuthenticated: (_flag) => {},
  setProfile: (_profile) => {},
  updateProfilePreferences: (preferences) => {},
  login: () => {},
  identity: () => {},
  logout: () => {},
  fetchCalendarEvents: (_date, _personType) => {},
  info: (_msg) => {},
  warning: (_msg) => {},
  error: (_msg) => {},
  fetchNonSchedulableDays: (_start, _end) => {},
  scheduleDayOn(date) {},
  scheduleDayOff(date) {},
  removeAllEventsFromDate: (date) => {},
  setSavingSomething: (_flag) => {},
});

export default AppContext;
