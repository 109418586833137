import axios from "axios";

export const generateAuthTokenAndOpenApp = async (e, callId) => {
  e.preventDefault();

  const token = (await axios.post("/auth/generate-auth-token")).data;

  if (token) {
    window.location.href = `wowlingo://authToken=${token}&callId=${callId}`;
  }
};
