import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "react-notifications-component/dist/theme.css";
import { notifyError } from "../components/notification";
import AppContext from "../context";

const ChangeUserPassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const navigate = useNavigate();
  const [formIsValid, setFormIsValid] = useState(false);
  const { setSavingSomething } = useContext(AppContext);

  const existingUserId = useParams().existingUserId;
  const forMe = !existingUserId;

  useEffect(() => {
    if (forMe) {
      setFormIsValid(
        !!oldPassword &&
          !!password &&
          !!repeatPassword &&
          password === repeatPassword
      );
    } else {
      setFormIsValid(
        !!password && !!repeatPassword && password === repeatPassword
      );
    }
  }, [forMe, password, repeatPassword]);

  async function submitPassword() {
    try {
      setSavingSomething(true);

      const response = await axios.post(
        `/person/${forMe ? "" : existingUserId + "/"}change-password`,
        {
          newPassword: password,
          ...(forMe ? { oldPassword } : {}),
        }
      );

      setSavingSomething(false);

      if (response.status === 200) {
        if (forMe) {
          navigate("/");
        } else {
          navigate("/users");
        }
      }
    } catch (e) {
      setSavingSomething(false);

      notifyError({
        title: "Błąd zmiany hasła",
      });
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      await submitPassword();
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <table>
          <thead>
            {forMe ? (
              <tr>
                <td>Stare hasło:</td>
                <td>
                  <input
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </td>
              </tr>
            ) : null}

            <tr>
              <td>Nowe hasło:</td>
              <td>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <td>Powtórz hasło:</td>
              <td>
                <input
                  type="password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </td>
            </tr>

            <tr>
              <td></td>
              <td>
                <button
                  onClick={() => {
                    if (forMe) {
                      navigate("/profile");
                    } else {
                      navigate("/users");
                    }
                  }}
                >
                  Anuluj
                </button>
                <button type="submit" disabled={!formIsValid}>
                  Zmień hasło
                </button>
              </td>
            </tr>
          </thead>
        </table>
      </form>
    </div>
  );
};

export default ChangeUserPassword;
