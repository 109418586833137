const DesktopApp = () => {
  return (
    <div>
      <div>
        Aplikacja desktopowa jest do pobrania (na razie tylko przez lektora).
        <br />
        <br />
        Proszę wybrać instalację odpowiednią dla swojego systemu operacyjnego.
      </div>

      <ul>
        <li>
          Mac{" "}
          <a href="https://download.wow3.smpl.pl/download/wowlingo.dmg">
            v1.0.6
          </a>
        </li>
        <li>
          Windows{" "}
          <a href="https://download.wow3.smpl.pl/download/wowlingo.exe">
            v1.0.10
          </a>
        </li>
        {/*<li>*/}
        {/*  Linux{" "}*/}
        {/*  <a href="https://download.wow3.smpl.pl/download/wowlingo.???">*/}
        {/*    v1.0.6*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </div>
  );
};

export default DesktopApp;
