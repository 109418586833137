import styled from "styled-components";

export const StyledSidebar = styled.div`
  position: absolute;
  right: 0;
  width: 30%;
  background-color: #fff;
  height: 100%;
  z-index: 10000;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  padding: 10px;
  border-left: 5px solid gray;
`;