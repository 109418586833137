import React, { useContext, useState } from "react";
import styled from "styled-components";
import AppContext from "../context";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../sidebar";
import { EventDetailsWrapper } from "../components/event-details-wrapper";

const Header = styled.div`
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  height: 35px;
  line-height: 35px;
`;

const MenuItems = styled.div`
  margin-left: 10px;
`;

const MenuItem = styled.span`
  margin-left: 10px;
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const FlexDiv = styled.div`
  display: flex;
  height: calc(100vh - 35px);
`;

const MainContent = styled.div`
  width: 100%;
  heigth: 100%;
`;

const Main = () => {
  const context = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Header>
        <MenuItems />
        <div>Kalendarz WOWlingo.com</div>
        <MenuItems>
          {context.authenticated ? (
            <>
              <StyledLink to="/logout">Wyloguj</StyledLink>
            </>
          ) : (
            <MenuItem>
              <StyledLink to="/login">Zaloguj</StyledLink>
            </MenuItem>
          )}
        </MenuItems>
      </Header>

      {context.authenticated && (
        <FlexDiv>
          <Sidebar addEventClicked={() => setShowModal(true)} />
          <EventDetailsWrapper
            show={showModal}
            onHide={() => setShowModal(false)}
          />
          <MainContent>
            <Outlet />
            {/*<Calendar />*/}
            {/*<Routes>*/}
            {/*  <Route path="calendar" element={<Calendar />} />*/}
            {/*  <Route path="users" element={<Users />} />*/}
            {/*</Routes>*/}
          </MainContent>
        </FlexDiv>
      )}
    </div>
  );
};

export default Main;
