import styled from "styled-components";

export const DivWithSpace = styled.div`
  margin: 7px 0;
  ${(props) => (props.alignToRight ? "text-align: right;" : "")}
  ${(props) => (props.alignToLeft ? "text-align: left;" : "")}
`;

DivWithSpace.defaultProps = {
  alignToRight: false,
  alignToLeft: false,
};
