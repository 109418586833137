import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AppContext from "../context";

const StyledInput = styled.input`
  margin: 10px 0;
  padding: 3px;
  text-align: right;
  width: 100%;
`;

const StyledButton = styled.button`
  margin: 10px 0 10px 10px;
  padding: 3px;
  ${(props) =>
    props.backgroundColor ? "background-color: ${props.backgroundColor);" : ""}
  ${(props) => (props.color ? "background-color: ${props.color);" : "")}
`;

const RightDiv = styled.div`
  text-align: right;
  width: 100%;
`;

const Form = styled.form`
  width: 300px;
  margin: 0 auto;
`;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [timeoutId, setTimeoutId] = useState(-1);

  const navigate = useNavigate();

  const { login } = useContext(AppContext);

  const submit = async (e) => {
    e.preventDefault();

    if (await login({ email, password })) {
      navigate("/calendar");
    } else {
      setError("Błąd logowania");

      if (timeoutId !== -1) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        setError("");
        setTimeoutId(-1);
      }, 5000);

      setTimeoutId(id);
    }
  };

  return (
    <div>
      <h3>Logowanie</h3>

      <Form onSubmit={submit}>
        <div>
          <StyledInput
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <StyledInput
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div>{error}</div>

          <RightDiv>
            <StyledButton type="button" onClick={() => navigate("/")}>
              Anuluj
            </StyledButton>
            <StyledButton type="submit">Zaloguj</StyledButton>
          </RightDiv>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
