import styled from "styled-components";

export const StyledTab = styled.div`
  font-size: small;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
  ${(props) => props.active && "background-color: #daeaff;"}
`;

export const StyledDeleteButton = styled.img`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  height: 15px;
`;

export const StyledTabs = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 2px;
`;
