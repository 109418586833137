import React from "react";
import EventDetailsSidebar from "../event-details-sidebar";
import { saveEvent } from "../../event";

export const EventDetailsWrapper = ({ show, onHide }) => {
  if (!show) {
    return null;
  }

  return (
    <EventDetailsSidebar
      closeModal={onHide}
      editedEvent={undefined}
      editedEventId={undefined}
      setEditedEvent={undefined}
      selectedDate={new Date()}
      saveEvent={saveEvent}
    />
  );
};
