import axios from "axios";

export const saveEvent = async (event, participants, occurrences) => {
  let response;
  let isOk;
  const participantsIds = participants.map((p) => p.id);
  const scheduledCallPayload = {
    label: event.label,
    weekRepeatCount: event.weekRepeatCount,
    participants: participantsIds,
  };
  if (event.id) {
    const editedPayload = {
      ...scheduledCallPayload,
      startAt: occurrences[0].data.startAt,
      endAt: occurrences[0].data.endAt,
    };
    response = await axios.put(
      `/calendar/scheduled_call/${event.id}`,
      editedPayload
    );
    isOk = response.status === 200;
  } else {
    for (const occurrence of occurrences) {
      const payload = {
        ...scheduledCallPayload,
        startAt: occurrence.data.startAt,
        endAt: occurrence.data.endAt,
        weekRepeatCount: occurrence.data.weekRepeatCount,
      };
      response = await axios.post(`/calendar/scheduled_call`, payload);
      isOk = response.status === 200;
    }

    // response = await axios.post(
    //   `/calendar/scheduled_call`,
    //   scheduledCallPayload
    // );
    // isOk = response.status === 200;
  }

  return isOk;
};
