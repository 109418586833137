import { Store } from "react-notifications-component";

const genericNotification = ({ title, message, type }) => {
  Store.addNotification({
    title,
    ...(message && { message }),
    type,
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
};
export const notifyError = ({ title, message }) => {
  genericNotification({ title, message, type: "danger" });
};

export const notifySuccess = ({ title, message }) => {
  genericNotification({ title, message, type: "success" });
};

export const notifyWarning = ({ title, message }) => {
  genericNotification({ title, message, type: "warning" });
};

export const notifyInfo = ({ title, message }) => {
  genericNotification({ title, message, type: "info" });
};
