import { StyledTabs } from "./style-components/tab";
import { Tab } from "./tab";

export const Tabs = ({ items, onSetActive, activeIndex, onDelete }) => {
  return (
    <StyledTabs>
      {items.map((item, index) => (
        <Tab
          key={index}
          item={item}
          canDelete={items.length > 1}
          onDelete={() => {
            onDelete(index);
          }}
          onActivate={() => {
            onSetActive(index);
          }}
          active={index === activeIndex}
        />
      ))}
    </StyledTabs>
  );
};
