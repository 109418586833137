import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledPersonSelector = styled.div`
  margin: 10px 0;
`;

const Title = styled.div`
  font-weight: strong;
  display: flex;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: small;
  color: gray;
`;

const InputFilter = styled.input`
  width: 50%;
  text-align: right;
`;

const StyledCleanLink = styled.a`
  text-decoration: none;
  color: gray;
  font-size: small;
`;

const StyledUsers = styled.div`
  max-height: 10rem;
  overflow: auto;
  border: 1px solid gray;
  padding: 5px 0;
`;

const StyledUser = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 5px;
  ${(props) =>
    !props.isSelected &&
    `
      &:hover {
        background-color: rgba(128, 128, 128, 0.2);
      }
    `}
  ${(props) =>
    props.isSelected &&
    `
    background-color: #3788d8;
    color: #fff;
  `}
`;
const StyledUserEmail = styled.span`
  font-style: italic;
`;

const PersonSelector = ({
  users,
  title,
  subtitle,
  selectedUsers,
  setSelectedUsers,
  multi,
}) => {
  const [filter, setFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (filter) {
      const searchString = filter.toLowerCase();
      const ffu = users.filter(
        (u) =>
          u.firstName.toLowerCase().indexOf(searchString) !== -1 ||
          u.surname.toLowerCase().indexOf(searchString) !== -1 ||
          u.email.toLowerCase().indexOf(searchString) !== -1
      );
      setFilteredUsers(ffu);
    } else {
      setFilteredUsers(users);
    }
  }, [users, filter]);

  function userIsSelected(userId) {
    return selectedUsers.indexOf(userId) !== -1;
  }

  function unselectUser(userId) {
    setSelectedUsers(selectedUsers.filter((id) => id !== userId));
  }

  function addUserToSelection(userId) {
    setSelectedUsers([...selectedUsers, userId]);
  }

  function handleClickOnUser(u) {
    const userId = u.id;

    if (userIsSelected(userId)) {
      unselectUser(userId);
    } else {
      if (multi) {
        addUserToSelection(userId);
      } else {
        setSelectedUsers([userId]);
      }
    }
  }

  return (
    <StyledPersonSelector>
      <Title>
        <div>
          <span>{title}</span>{" "}
          <StyledCleanLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedUsers([]);
            }}
          >
            Wyczyść
          </StyledCleanLink>
        </div>

        <InputFilter
          type="text"
          value={filter}
          placeholder="Filtruj..."
          onChange={(e) => setFilter(e.target.value.trim())}
        />
      </Title>
      <Subtitle>{subtitle}</Subtitle>
      <StyledUsers>
        {filteredUsers.map((u) => (
          <StyledUser
            key={u.id}
            onClick={() => handleClickOnUser(u)}
            isSelected={selectedUsers.indexOf(u.id) !== -1}
          >
            <div>
              {u.firstName} {u.surname}
            </div>
            <StyledUserEmail>{u.email}</StyledUserEmail>
          </StyledUser>
        ))}
      </StyledUsers>
    </StyledPersonSelector>
  );
};

export default PersonSelector;
