import React, { useContext, useEffect, useRef, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { StyledButton } from "../button";
import axios from "axios";
import { endOfDay, format, startOfDay } from "date-fns";
import AppContext from "../../context";
import PersonSelector from "./person-selector";
import { IdComponent } from "./style-componets/id-component";
import { LeftAndRight } from "./style-componets/left-and-right";
import { ButtonGroup } from "./style-componets/button-group";
import { Title } from "./style-componets/title";
import { StyledSidebar } from "./style-componets/styled-siderbar";
import { DivWithSpace } from "./div-with-space";
import { Tabs } from "../../calendar/tabs";
import { notifyError } from "../notification";

const EventDetailsSidebar = ({
  saveEvent,
  closeModal,
  editedEventId,
  selectedDate,
  deleteEvent,
}) => {
  const editMode = !!editedEventId;
  const initialEventEmptyState = {
    label: "",
    startAt: null,
    endAt: null,
    weekRepeatCount: 1,
  };
  const [event, setEvent] = useState({ ...initialEventEmptyState });
  const [participants, setParticipants] = useState([]);
  const [valid, setValid] = useState();
  const [errorFetchingEventDetails, setErrorFetchingEventDetails] =
    useState(false);
  const [errorFetchingParticipants, setErrorFetchingParticipants] =
    useState(false);
  const inputRef = useRef();
  const showBots = false;

  const [hasOneLector, setHasOneLector] = useState(false);
  const [hasListeners, setHasListeners] = useState(false);
  const [occurrences, setOccurrences] = useState([]);
  const [activeOccurrenceIndex, setActiveOccurrenceIndex] = useState(0);

  const { users } = useContext(AppContext);

  const setError = (message) => {
    if (message) {
      notifyError({ title: message });
    }
  };

  const lectors = users.filter(({ personType }) => personType === "Lector");
  const listeners = users.filter(({ personType }) => personType === "Listener");
  const recorderBots = users.filter(
    ({ personType }) => personType === "RecorderBot"
  );

  const deleteOccurrence = (index) => {
    setOccurrences((prev) => {
      const result = prev.filter((_, i) => i !== index);
      return result;
    });
    setActiveOccurrenceIndex(0);
  };

  const setPropertyOfActiveOccurrence = (property, value) => {
    console.log("weekRepeatCount", property, value);
    setOccurrences((prev) => {
      const result = prev.map((occurrence, index) => {
        if (index === activeOccurrenceIndex) {
          return {
            ...occurrence,
            data: {
              ...occurrence.data,
              ...(property === "endAt" ? { endAt: value } : {}),
              ...(property === "weekRepeatCount"
                ? { weekRepeatCount: value }
                : {}),
              ...(property === "startAt" ? { startAt: value } : {}),
            },
          };
        } else {
          return occurrence;
        }
      });
      return result;
    });
  };

  const saveThisEvent = async () => {
    const isOk = await saveEvent(event, participants, occurrences);

    if (isOk) {
      setError("");
      closeModal();
    } else {
      setError("Błąd przy zapisie wydarzenia.");
    }
  };

  const deleteThisEvent = async () => {
    try {
      await deleteEvent(event.id);

      closeModal();
    } catch (error) {
      console.log(error.response?.data?.message);
      if (error.response?.data?.message === "session-took-place") {
        setError("Nie można usunąć wydarzenia, które już się odbyło.");
      } else {
        setError("Błąd przy usuwaniu wydarzenia.");
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    async function fetchEventDetailsAndParticipants(editedEventId) {
      const response = await axios.get(
        `/calendar/scheduled_call/${editedEventId}`
      );
      if (response.status === 200) {
        const data = response.data;
        data.startAt = new Date(data.startAt);
        data.endAt = new Date(data.endAt);
        setEvent(data);

        setOccurrences([
          {
            label: format(data.startAt, "yyyy-MM-dd"),
            date: data.startAt,
            data: {
              startAt: data.startAt,
              endAt: data.endAt,
              weekRepeatCount: data.weekRepeatCount,
            },
            active: true,
          },
        ]);
      } else {
        setErrorFetchingEventDetails(true);
      }

      const participantsResponse = await axios.get(
        `/calendar/scheduled_call/${editedEventId}/participants`
      );
      if (participantsResponse.status === 200) {
        const data = participantsResponse.data;
        setParticipants(data);
      } else {
        setErrorFetchingParticipants(true);
      }
    }

    if (editedEventId) {
      fetchEventDetailsAndParticipants(editedEventId);
    } else {
      setEvent({
        ...initialEventEmptyState,
      });

      setOccurrences([
        {
          label: format(selectedDate, "yyyy-MM-dd"),
          date: selectedDate,
          data: {
            startAt: startOfDay(selectedDate),
            endAt: endOfDay(selectedDate),
            weekRepeatCount: 1,
          },
          active: true,
        },
      ]);

      setParticipants([]);
    }
  }, [editedEventId, selectedDate]);

  // useEffect to set hasOneLector and hasListeners
  useEffect(() => {
    setHasOneLector(
      participants.filter(({ personType }) => personType === "Lector")
        .length === 1
    );
    setHasListeners(
      participants.some(({ personType }) => personType === "Listener")
    );
  }, [participants]);

  useEffect(() => {
    const occurrencesValid =
      occurrences.every((occurrence) => {
        return (
          occurrence.data.startAt != null &&
          occurrence.data.endAt != null &&
          occurrence.data.endAt > occurrence.data.startAt
        );
      }) && occurrences.length > 0;

    setValid(
      !!event.label &&
        event.label.length > 0 &&
        hasOneLector &&
        hasListeners &&
        occurrencesValid
    );
  }, [event, hasOneLector, hasListeners, occurrences]);

  if (!event) {
    return null;
  }

  function setLectorInParticipants(lectorId) {
    setParticipants((prevState) => {
      return prevState
        .filter(({ personType }) => personType !== "Lector")
        .concat({
          ...users.find(({ id }) => id === lectorId),
        });
    });
  }

  function setListenersInParticipants(listenerIds) {
    setParticipants((prevState) => {
      return prevState
        .filter(({ personType }) => personType !== "Listener")
        .concat(
          listenerIds.map((listenerId) => {
            return {
              ...users.find(({ id }) => id === listenerId),
            };
          })
        );
    });
  }

  function setRecorderBotsInParticipants(listenerIds) {
    setParticipants((prevState) => {
      return prevState
        .filter(({ personType }) => personType !== "RecorderBot")
        .concat(
          listenerIds.map((listenerId) => {
            return {
              ...users.find(({ id }) => id === listenerId),
            };
          })
        );
    });
  }

  if (errorFetchingEventDetails || errorFetchingParticipants) {
    return (
      <StyledSidebar>
        <div>
          <p>Wystąpił błąd podczas pobierania szczegółów wydarzenia</p>
        </div>
      </StyledSidebar>
    );
  }

  const addOccurrence = (e) => {
    e.preventDefault();

    setOccurrences((prev) => {
      const lastOccurrence = prev[prev.length - 1];

      const date = new Date(lastOccurrence.date);

      const nextDay = new Date(date.setDate(date.getDate() + 1));

      const nextDayStart = new Date(lastOccurrence.data.startAt);
      nextDayStart.setDate(nextDayStart.getDate() + 1);

      const nextDayEnd = new Date(lastOccurrence.data.endAt);
      nextDayEnd.setDate(nextDayEnd.getDate() + 1);

      const newOccurrence = {
        label: format(nextDay, "yyyy-MM-dd"),
        date: nextDay,
        data: {
          startAt: nextDayStart,
          endAt: nextDayEnd,
          weekRepeatCount: lastOccurrence.data.weekRepeatCount,
        },
      };
      setActiveOccurrenceIndex(prev.length);

      const result = prev.concat(newOccurrence);

      return result;
    });
  };

  return (
    <StyledSidebar>
      <Title>{editMode ? "Edycja lekcji" : "Nowa lekcja"}</Title>
      {editMode ? <IdComponent>id: {event.id}</IdComponent> : null}

      <hr />

      <>
        <div></div>
        <form>
          <LeftAndRight>
            <div>Nazwa: </div>
            <input
              ref={inputRef}
              type="text"
              value={event.label}
              onChange={(e) => {
                setEvent((prev) => ({
                  ...prev,
                  label: e.target.value,
                }));
              }}
            />
          </LeftAndRight>
          <hr />
          <h4>
            Wystąpienia <button onClick={addOccurrence}>Dodaj</button>
          </h4>

          <Tabs
            items={occurrences}
            onSetActive={setActiveOccurrenceIndex}
            activeIndex={activeOccurrenceIndex}
            onDelete={deleteOccurrence}
          />

          {occurrences.length > 0 ? (
            <>
              <LeftAndRight>
                <div>Początek: </div>
                <DateTimePicker
                  // value={event.startAt}
                  value={occurrences[activeOccurrenceIndex].data.startAt}
                  onChange={(value) => {
                    setPropertyOfActiveOccurrence("startAt", value);
                  }}
                />
              </LeftAndRight>
              <LeftAndRight>
                <div>Koniec: </div>
                <DateTimePicker
                  // value={event.endAt}
                  value={occurrences[activeOccurrenceIndex].data.endAt}
                  onChange={(value) => {
                    setPropertyOfActiveOccurrence("endAt", value);
                  }}
                />
              </LeftAndRight>

              <div>
                Powtarzaj przez{" "}
                <input
                  type="number"
                  value={
                    occurrences[activeOccurrenceIndex].data.weekRepeatCount
                  }
                  onChange={(e) => {
                    if (e.target.value < 1) {
                      return;
                    } else {
                      try {
                        setPropertyOfActiveOccurrence(
                          "weekRepeatCount",
                          parseInt(e.target.value, 10)
                        );
                      } catch {}
                    }
                  }}
                />{" "}
                tygodni
              </div>
            </>
          ) : null}

          <hr />
          <PersonSelector
            users={lectors}
            title="Lektor:"
            multi={false}
            selectedUsers={participants
              .filter((p) => p.personType === "Lector")
              .map((p) => p.id)}
            setSelectedUsers={([lectorId]) => {
              if (lectorId) {
                setLectorInParticipants(lectorId);
              }
            }}
          />
          <PersonSelector
            multi={true}
            users={listeners}
            title="Słuchacze:"
            selectedUsers={participants
              .filter((p) => p.personType === "Listener")
              .map((p) => p.id)}
            setSelectedUsers={(listenerIds) => {
              setListenersInParticipants(listenerIds);
            }}
          />
          {showBots ? (
            <PersonSelector
              multi={false}
              users={recorderBots}
              title="Boty nagrywające:"
              subtitle="Ustaw pojedyczego (dowolnego) bota jeśli chcesz nagrywać rozmowę"
              selectedUsers={participants
                .filter((p) => p.personType === "RecorderBot")
                .map((p) => p.id)}
              setSelectedUsers={(listenerIds) => {
                setRecorderBotsInParticipants(listenerIds);
              }}
            />
          ) : null}
        </form>

        <DivWithSpace>
          <ButtonGroup>
            {editMode ? (
              <StyledButton onClick={deleteThisEvent}>Usuń lekcję</StyledButton>
            ) : null}

            <StyledButton onClick={closeModal}>Zamknij</StyledButton>
            <StyledButton disabled={!valid} onClick={saveThisEvent}>
              Zapisz
            </StyledButton>
          </ButtonGroup>
        </DivWithSpace>
      </>
    </StyledSidebar>
  );
};

export default EventDetailsSidebar;
